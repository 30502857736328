@import "_variables";

$maxArticleWith: 750px;
$sidenavWidth: 250px;
$sideMargin: 55px;

.hc-content {
  min-height: $hc-page-content-height;
  margin: 0 auto;
  display: flex;

  .left {
    background-color: $site-bg-color-200;
    flex: 0 0 auto;
    border-right: 1px solid $site-border-color-200;
    padding-left: calc((100% - #{$hc-page-max-width}) / 2);
    width: calc((100% - #{$hc-page-max-width}) / 2 + #{$sidenavWidth});
    min-width: $sidenavWidth;
  }

  .center {
    position: relative;
    flex: 1 1 auto;
    min-width: 0;
    background-color: $site-bg-color-100;

    > article, > .category-articles {
      margin: 30px $sideMargin;
      max-width: 750px;
    }
  }

  @media only screen and (max-width: $tablet) {
    .left {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-255px);
      box-shadow: 6px 0 6px rgba(0,0,0,.1);
      transition: transform 225ms cubic-bezier(.4,0,.2,1);
      z-index: 10;

      &.mobile-visible {
        transform: none;
      }

      .sidenav-content {
        height: 100vh;
        max-height: none;
      }

      .mobile-toggle {
        display: flex;
      }
    }
  }

  @media only screen and (max-width: $sidenavWidth + $maxArticleWith + ($sideMargin * 2)) {
    .center {
      > article, > .category-articles {
        max-width: calc(100% - 40px);
        margin: 20px;
      }
    }
  }
}