@import "variables";

body, html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  touch-action: manipulation;
}

body {
  font-family: $font-family;
  color: $site-text-color-400;
  line-height: 1.42857143;
  font-size: 1.4rem;
  margin: 0;
  overflow: hidden;
}

//box-sizing
body {
  box-sizing: inherit;

  *, *:before, *:after {
    box-sizing: inherit;
  }
}